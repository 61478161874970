import {getArticlesData} from 'blocks/articles/articles_graph'
import {getBackgroundblockData} from 'blocks/backgroundblock/backgroundblock_graph'
import {BlockType} from 'blocks/block'
import {getBlocks2Data} from 'blocks/blocks2/blocks2_graph'
import {getCalendarData} from 'blocks/calendar/calendar_graph'
import {getCasedData} from 'blocks/cases/cases_graph'
import {getColumns2Data} from 'blocks/columns2/columns2_graph'
import {getColumns3Data} from 'blocks/columns3/columns3_graph'
import {getContactData} from 'blocks/contact/contact_graph'
import {getContactformData} from 'blocks/contactform/contactform_graph'
import {getGalleryData} from 'blocks/gallery/gallery_graph'
import {getHeroData} from 'blocks/hero/hero_graph'
import {getHerohomeData} from 'blocks/herohome/herohome_graph'
import {getImageblockData} from 'blocks/imageblock/imageblock_graph'
import {getImagetextData} from 'blocks/imagetext/imagetext_graph'
import {getInpagenavData} from 'blocks/inpagenav/inpagenav_graph'
import {getLinksData} from 'blocks/links/links_graph'
import {getListData} from 'blocks/list/list_graph'
import {getNewsData} from 'blocks/news/news_graph'
import {getOfficesData} from 'blocks/offices/offices_graph'
import {getOverviewData} from 'blocks/overview/overview_graph'
import {getProcessData} from 'blocks/process/process_graph'
import {getRelatedData} from 'blocks/related/related_graph'
import {getServicesData} from 'blocks/services/services_graph'
import {getTestimonialsData} from 'blocks/testimonials/testimonials_graph'
import {getVideoblockData} from 'blocks/videoblock/videoblock_graph'
import {graphql} from 'gatsby'
import React from 'react'
import {BlocksFragment} from 'types/graphtypes'
import {getIframeblockData} from './iframeblock/iframeblock_graph'
import {getProfileData} from './profile/profile_graph'
import {getReadingroomData} from './readingroom/readingroom_graph'
import {getTextblockData} from './textblock/textblock_graph'
import {getSearchData} from './search/search_graph'
import {getAgendaData} from './agenda/agenda_graph'
import {getAnchorData} from './anchor/anchor_graph'
import {getDownloadsData} from './downloads/downloads_graph'
import {getSponsorsData} from './sponsors/sponsors_graph'
import {getCenteredData} from './centered/centered_graph'
import {getRelatedjobsData} from './relatedjobs/relatedjobs_graph'
import {getIconIntroData} from 'blocks/iconintro/iconintro_graph'
import {getErrorData} from 'blocks/error/error_graph'
import {getJobsData} from 'blocks/jobs/jobs_graph'
import {getProductsData} from 'blocks/products/products_graph'
import {getProductHeroData} from 'blocks/producthero/producthero_graph'
import {getProductsPreviewData} from 'blocks/productspreview/productspreview_graph'
import {getFaqData} from 'blocks/faq/faq_graph'
import {getSpecificationsData} from 'blocks/specifications/specifications_graph'

export function loadBlocksFromGraph(
	fragments: Array<BlocksFragment>
): Array<BlockType> {
	let result = []
	if(!fragments) return []

	fragments.forEach(paragraph => {
		const blockOrBlockArray = loadBlockFromGraph(paragraph)

		if (Array.isArray(blockOrBlockArray)) {
			result = [...result, ...blockOrBlockArray]
		} else {
			result = [...result, blockOrBlockArray]
		}
	})

	return result
}

function loadBlockFromGraph(
	frag: BlocksFragment
): BlockType | Array<BlockType> {

	switch (frag.type) {
		case 'paragraph__services':
			return { type: 'services', ...getServicesData(frag) }
		case 'paragraph__textblock':
			return { type: 'textblock', ...getTextblockData(frag) }
		case 'paragraph__herohome':
			return { type: 'herohome', ...getHerohomeData(frag) }
		case 'paragraph__articles':
			return { type: 'articles', ...getArticlesData(frag) }
		case 'paragraph__backgroundblock':
			return { type: 'backgroundblock', ...getBackgroundblockData(frag) }
		case 'paragraph__blocks2':
			return { type: 'blocks2', ...getBlocks2Data(frag) }
		case 'paragraph__calendar':
			return { type: 'calendar', ...getCalendarData(frag) }
		case 'paragraph__columns3':
			return { type: 'columns3', ...getColumns3Data(frag) }
		case 'paragraph__hero':
			return {type: 'hero', ...getHeroData(frag)}
		case 'paragraph__news':
			return { type: 'news', ...getNewsData(frag) }
		case 'paragraph__cases':
			return { type: 'cases', ...getCasedData(frag) }
		case 'paragraph__columns2':
			return { type: 'columns2', ...getColumns2Data(frag) }
		case 'paragraph__contact':
			return { type: 'contact', ...getContactData(frag) }
		case 'paragraph__contactform':
			return { type: 'contactform', ...getContactformData(frag) }
		case 'paragraph__imagetext':
			return { type: 'imagetext', ...getImagetextData(frag) }
		case 'paragraph__links':
			return { type: 'links', ...getLinksData(frag) }
		case 'paragraph__list':
			return { type: 'list', ...getListData(frag) }
		case 'paragraph__offices':
			return { type: 'offices', ...getOfficesData(frag) }
		case 'paragraph__overview':
			return { type: 'overview', ...getOverviewData(frag) }
		case 'paragraph__process':
			return { type: 'process', ...getProcessData(frag) }
		case 'paragraph__related':
			return { type: 'related', ...getRelatedData(frag) }
		case 'paragraph__testimonials':
			return { type: 'testimonials', ...getTestimonialsData(frag) }
		case 'paragraph__imageblock':
			return { type: 'imageblock', ...getImageblockData(frag) }
		case 'paragraph__videoblock':
			return { type: 'videoblock', ...getVideoblockData(frag) }
		case 'paragraph__reading_room':
			return { type: 'reading_room', ...getReadingroomData(frag) }
		case 'paragraph__profile':
			return { type: 'profile', ...getProfileData(frag) }
		case 'paragraph__gallery':
			return { type: 'gallery', ...getGalleryData(frag) }
		case 'paragraph__inpagenav':
			return { type: 'inpagenav', ...getInpagenavData(frag) }
		case 'paragraph__iframeblock':
			return { type: 'iframeblock', ...getIframeblockData(frag) }
		case 'paragraph__search':
			return { type: 'search', ...getSearchData(frag) }
		case 'paragraph__agenda':
			return { type: 'agenda', ...getAgendaData(frag) }
		case 'paragraph__anchor':
			return { type: 'anchor', ...getAnchorData(frag) }
		case 'paragraph__prototype':
			const prototypeFrag =
				frag.relationships.field_prototype_prototype.relationships.field_blocks
			return loadBlocksFromGraph(prototypeFrag)
		case 'paragraph__icon_overview':
			return { type: 'icon_overview' }
		case 'paragraph__downloads':
			return { type: 'downloads', ...getDownloadsData(frag) }
		case 'paragraph__sponsors':
			return { type: 'sponsors', ...getSponsorsData(frag) }
		case 'paragraph__request':
			return { type: 'request' }
		case 'paragraph__share':
			return { type: 'share' }
		case 'paragraph__iconintro':
			return { type: 'iconintro', ...getIconIntroData(frag) }
		case 'paragraph__centered':
			return { type: 'centered', ...getCenteredData(frag) }
		case 'paragraph__error':
			return {type: 'error', ...getErrorData(frag)}
		case 'paragraph__relatedjobs':
			return {type: 'relatedjobs', ...getRelatedjobsData(frag)}
		case 'paragraph__jobs':
			return {type: 'jobs', ...getJobsData(frag)}
		case 'paragraph__products':
			return {type: 'products', ...getProductsData(frag)}
		case 'paragraph__producthero':
			return {type: 'producthero', ...getProductHeroData(frag)}
		case 'paragraph__productspreview':
			return {type: 'productspreview', ...getProductsPreviewData(frag)}
		case 'paragraph__faq':
			return {type: 'faq', ...getFaqData(frag)}
		case 'paragraph__specifications':
			return {type: 'specifications', ...getSpecificationsData(frag)}
	}

	return []
}

export const fragment = graphql`
	fragment Blocks on paragraph_unions {
		type: __typename
		...Services
		...Textblock
		...Herohome
		...Articles
		...BackgroundBlock
		...Blocks2
		...Calendar
		...Columns3
		...Hero
		...News
		...Cases
		...Columns2
		...Contact
		...Contactform
		...Imagetext
		...Links
		...List
		...Offices
		...Overview
		...Process
		...Related
		...Testimonials
		...ImageBlock
		...VideoBlock
		...Readingroom
		...Profile
		...Gallery
		...Inpagenav
		...IframeBlock
		...Search
		...Agenda
		...Anchor
		...Prototype
		...Downloads
		...Sponsors
		...Centered
		...Error
		...Relatedjobs
		...Iconintro
		...Jobs
		...Products
		...ProductHero
		...ProductsPreview
		...Faq
		...Specifications
	}
`
