import { HeroType } from 'blocks/hero/hero'
import { graphql } from 'gatsby'
import { FixedObject } from 'gatsby-image'
import { ImageType } from 'types/basictypes'
import { GraphMedia, HeroFragment } from 'types/graphtypes'
import { getImageFromField, getWysiwygFromField } from 'util/drupal'
import { previewFragmentToPreviewItem } from 'layout/preview/preview_graph'


export const getHeroBackgroundImage: (mediaFrag: GraphMedia) => ImageType = (
	mediaFrag
) => {
	if (!mediaFrag) return null
	const mobile = getImageFromField(mediaFrag, 'mobile')
	const desktop = getImageFromField(mediaFrag, 'image')
	if (!mobile || !desktop) return null

	return {
		...desktop,
		fixed: [
			mobile.fixed as FixedObject,
			{ ...(desktop.fixed as FixedObject), media: '(min-width: 600px)' }
		]
	}
}

export const getHeroData: (frag: HeroFragment) => HeroType = (frag) => {
	const {
		field_hero_title,
		field_hero_description,
		fields,
		relationships: { field_hero_background_image, field_hero_mp4, field_hero_product }
	} = frag

	return {
		title: field_hero_title,
		description: getWysiwygFromField(field_hero_description),
		background_image: getHeroBackgroundImage(field_hero_background_image),
		buttons: fields?.field_hero_buttons,
		back: fields?.field_hero_back,
		mp4: field_hero_mp4?.relationships?.field_media_video_file?.localFile?.url,
		product: previewFragmentToPreviewItem(field_hero_product?.fields?.preview)
	}
}

export const fragment = graphql`
	fragment Hero on paragraph__hero {
		fields {
			field_hero_buttons {
				title
				url
			}
			field_hero_back {
				url
				title
			}
		}
		field_hero_description {
			processed
		}
		field_hero_title
		relationships {
			field_hero_background_image {
				field_overlay_percentage
				field_use_dark_colors
				...HeroImage
			}
			field_hero_mp4 {
				name
				relationships {
					field_media_video_file {
						localFile {
							url
						}
					}
				}
			}
			field_hero_product {
				fields {
					preview {
						...Preview
					}
				}
			}
		}
	}
`

export const fragmentImage = graphql`
	fragment HeroImage on media__image {
		...ImageMeta
		relationships {
			field_media_image {
				localFile {
					mobile: childImageSharp {
						fixed(width: 375, height: 700) {
							...GatsbyImageSharpFixed
						}
					}
					image: childImageSharp {
						fixed(width: 1400) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`
