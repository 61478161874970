import {OverviewItemType, OverviewType} from 'blocks/overview/overview'
import {graphql} from 'gatsby'
import React from 'react'
import {OverviewFragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getOverviewData: (frag: OverviewFragment) => OverviewType = (
	frag
) => {
	const {
		field_overview_title,
		field_overview_columns,
		relationships: {field_overview_items},
		field_overview_landscape,
		field_overview_textual_display
	} = frag

	let items: Array<OverviewItemType> = []

	const displaySquare = !field_overview_landscape
	const imageKey = displaySquare ? 'imageSquare' : 'image'

	field_overview_items.forEach((item) => {
		if (item.__typename === 'paragraph__overview_item') {
			const image = getImageFromField(
				item.relationships.field_overview_item_image,
				imageKey
			)
			const text = getWysiwygFromField(item.field_overview_item_text)
			const link = item.fields.field_overview_item_link

			items.push({image, link, text})
		} else if (item.__typename === 'paragraph__overview_link') {
			const page = item.fields?.page
			if(!page) return

			const image = getImageFromField(page.image, imageKey)
			const text = `<h5>${page.title}</h5>${page.description}`
			const link = {url: page.url}

			items.push({image, link, text})
		}
	})

	return {
		title: field_overview_title,
		columns: field_overview_columns,
		items: items,
		display_square: !field_overview_landscape,
		display_textual: field_overview_textual_display
	}
}

export const fragment = graphql`
	fragment Overview on paragraph__overview {
		id
		field_overview_title
		field_overview_columns
		field_overview_landscape
		field_overview_textual_display
		relationships {
			field_overview_items {
				... on paragraph__overview_item {
					field_overview_item_text {
						processed
					}
					fields {
						field_overview_item_link {
							url
						}
					}
					relationships {
						field_overview_item_image {
							...OverviewImage
						}
					}
				}
				... on paragraph__overview_link {
					fields {
						page {
							title
							url
							description
							image {
								...OverviewImage
							}
						}
					}
				}
			}
		}
	}
`

export const fragmentImage = graphql`
	fragment OverviewImage on media__image {
		...ImageMeta
		relationships {
			field_media_image {
				localFile {
					absolutePath
					image: childImageSharp {
						fixed(width: 640, height: 278, cropFocus: CENTER) {
							...GatsbyImageSharpFixed
						}
					}
					imageSquare: childImageSharp {
						fixed(width: 640, height: 640, cropFocus: CENTER) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`
