import {LinksType} from 'blocks/links/links'
import {graphql} from 'gatsby'
import React from 'react'
import {LinksFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'

export const getLinksData: (frag: LinksFragment) => LinksType = frag => {
	const {
		field_links_text,
		fields: {field_links_cta_link, field_links_links}
	} = frag

	return {
		text: getWysiwygFromField(field_links_text),
		links: field_links_links,
		cta_link: field_links_cta_link
	}
}

export const fragment = graphql`
	fragment Links on paragraph__links {
		id
		field_links_text {
			processed
		}
		fields {
			field_links_cta_link {
				url
				title
			}
			field_links_links {
				url
				title
			}
		}
	}
`
