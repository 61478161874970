import React from 'react'
import {getWysiwygFromField} from 'util/drupal'
import {graphql} from 'gatsby'
import {HerohomeFragment} from 'types/graphtypes'
import {HeroHomeType} from 'blocks/herohome/herohome'

export const getIconIntroData: (
	frag: HerohomeFragment
) => HeroHomeType = frag => {
	const {field_iconintro_description} = frag

	const description = getWysiwygFromField(field_iconintro_description)

	return {
		description: description,
	}
}

export const fragment = graphql`
	fragment Iconintro on paragraph__iconintro {
		id
		field_iconintro_description {
			processed
		}
	}
`
