import React from 'react'
import {getWysiwygFromField} from 'util/drupal'
import {graphql} from 'gatsby'
import {Blocks2Type} from 'blocks/blocks2/blocks2'
import {Blocks2Fragment} from 'types/graphtypes'

export const getBlocks2Data: (frag: Blocks2Fragment) => Blocks2Type = frag => {
  const {
    field_blocks2_block_left,
    field_blocks2_block_right
  } = frag

  return {
    block_left: getWysiwygFromField(field_blocks2_block_left),
    block_right: getWysiwygFromField(field_blocks2_block_right)
  }
}

export const fragment = graphql`
  fragment Blocks2 on paragraph__blocks2 {
    id
    field_blocks2_block_left {
      processed
    }
    field_blocks2_block_right {
      processed
    }
  }
`
