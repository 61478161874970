import {OfficesType} from 'blocks/offices/offices'
import {graphql} from 'gatsby'
import React from 'react'
import {OfficesFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'

export const getOfficesData: (frag: OfficesFragment) => OfficesType = frag => {
	const {
		relationships: {field_offices_items}
	} = frag

	const offices = field_offices_items.map(office => {
		return {
			title: office.field_offices_item_title,
			text: getWysiwygFromField(office.field_offices_item_text)
		}
	})

	return {
		items: offices
	}
}

export const fragment = graphql`
	fragment Offices on paragraph__offices {
		id
		relationships {
			field_offices_items {
				field_offices_item_text {
					processed
				}
				field_offices_item_title
			}
		}
	}
`
