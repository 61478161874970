import React from 'react'
import {graphql} from 'gatsby'
import {ProfileFragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'
import {ProfileType} from './profile'

export const getProfileData: (frag: ProfileFragment) => ProfileType = frag => {
	const {
		field_profile_text,
		relationships: {field_profile_image}
	} = frag

	return {
		image: getImageFromField(field_profile_image),
		text: getWysiwygFromField(field_profile_text)
	}
}

export const fragment = graphql`
	fragment Profile on paragraph__profile {
		field_profile_text {
			processed
		}
		relationships {
			field_profile_image {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							absolutePath
							image: childImageSharp {
								fixed(width: 600) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	}
`
