import React from 'react'
import {graphql} from 'gatsby'
import {AnchorFragment} from 'types/graphtypes'
import {AnchorType} from './anchor'

export const getAnchorData: (frag: AnchorFragment) => AnchorType = frag => {
	const {field_anchor_id, field_anchor_title} = frag

	return {
		id: field_anchor_id,
		title: field_anchor_title
	}
}

export const fragment = graphql`
	fragment Anchor on paragraph__anchor {
		field_anchor_id,
		field_anchor_title
	}
`
