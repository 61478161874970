import React from 'react'
import {graphql} from 'gatsby'
import {ContactformFragment} from 'types/graphtypes'
import {ContactFormType} from 'blocks/contactform/contactform'

export const getContactformData: (frag: ContactformFragment) => ContactFormType = frag => {
	const {
		field_contactform_title,
		field_contactform_company_fields,
		field_contactform_enable_bar
	} = frag

	return {
		title: field_contactform_title,
		company_fields: field_contactform_company_fields,
		enable_bar: field_contactform_enable_bar
	}
}

export const fragment = graphql`
	fragment Contactform on paragraph__contactform {
		id
		field_contactform_title
		field_contactform_company_fields
		field_contactform_enable_bar
	}
`
