import {ImagetextType} from 'blocks/imagetext/imagetext'
import {graphql} from 'gatsby'
import React from 'react'
import {ImagetextFragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getImagetextData: (frag: ImagetextFragment) => ImagetextType = (
	frag
) => {
	const {field_imagetext_text, relationships, field_imagetext_image_left} = frag

	const images = relationships.field_imagetext_images.map((field) =>
		getImageFromField(field)
	)

	return {
		text: getWysiwygFromField(field_imagetext_text),
		images,
		image_position: field_imagetext_image_left ? 'left' : 'right'
	}
}

export const fragment = graphql`
	fragment Imagetext on paragraph__imagetext {
		id
		field_imagetext_text {
			processed
		}
		relationships {
			field_imagetext_images {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							absolutePath
							image: childImageSharp {
								fixed(width: 650, height: 650, cropFocus: CENTER) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
		field_imagetext_image_left
	}
`
