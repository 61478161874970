import {VideoblockType} from 'blocks/videoblock/videoblock'
import {graphql} from 'gatsby'
import React from 'react'
import {VideoBlockFragment} from 'types/graphtypes'

export const getVideoblockData: (
	frag: VideoBlockFragment
) => VideoblockType = frag => {
	const {
		field_videoblock_embed_code,
		relationships: {field_videoblock_size}
	} = frag

	return {
		size: field_videoblock_size && field_videoblock_size.name,
		embed_code: field_videoblock_embed_code
	}
}

export const fragment = graphql`
	fragment VideoBlock on paragraph__videoblock {
		id
		field_videoblock_embed_code
		relationships {
			field_videoblock_size {
				name
			}
		}
	}
`
