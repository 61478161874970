import {CasesType} from 'blocks/cases/cases'
import {graphql} from 'gatsby'
import React from 'react'
import {CasesFragment} from 'types/graphtypes'
import {getImageFromField} from 'util/drupal'

export const getCasedData: (frag: CasesFragment) => CasesType = frag => {
	const {relationships} = frag

	const items = relationships.field_cases_items.map(item => {
		return {
			title: item.field_cases_item_title,
			description: item.field_cases_item_description,
			link: item.fields.field_cases_item_link,
			image: getImageFromField(item.relationships.field_cases_background_image),
			overlay:
				item.relationships.field_cases_background_image &&
				item.relationships.field_cases_background_image.field_overlay_percentage
		}
	})

	return {
		items
	}
}

export const fragment = graphql`
	fragment Cases on paragraph__cases {
		id
		relationships {
			field_cases_items {
				field_cases_item_title
				field_cases_item_description
				fields {
					field_cases_item_link {
						title
						url
					}
				}
				relationships {
					field_cases_background_image {
						...ImageMeta
						relationships {
							field_media_image {
								localFile {
									image: childImageSharp {
										fluid(maxWidth: 1200) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
