import {InpagenavType} from 'blocks/inpagenav/inpagenav'
import {graphql} from 'gatsby'
import React from 'react'
import {InpagenavFragment} from 'types/graphtypes'

export const getInpagenavData: (frag: InpagenavFragment) => InpagenavType = (
	frag
) => {
	const {field_inpagenav_display_dark, fields} = frag

	return {
		displayDark: field_inpagenav_display_dark,
		links: (fields?.field_inpagenav_links || []).map((link) => ({
			id: link.url,
			label: link.title
		})),
		cta: fields?.field_inpagenav_cta
	}
}

export const fragment = graphql`
	fragment Inpagenav on paragraph__inpagenav {
		field_inpagenav_display_dark
		fields {
			field_inpagenav_links {
				title
				url
			}
			field_inpagenav_cta {
				title
				url
			}
		}
	}
`
