import {Columns2Type} from 'blocks/columns2/columns2'
import {graphql} from 'gatsby'
import React from 'react'
import {Columns2Fragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getColumns2Data: (
	frag: Columns2Fragment
) => Columns2Type = frag => {
	const {
		field_columns2_display_square,
		field_columns2_text_left,
		field_columns2_text_right,
		fields,
		relationships
	} = frag

	const displaySquare = field_columns2_display_square

	const imageKey = displaySquare ? 'imageSquare' : 'image'
	const image_left = getImageFromField(relationships.field_columns2_image_left, imageKey)
	const image_right = getImageFromField(relationships.field_columns2_image_right, imageKey)

	return {
		display_square: displaySquare,
		image_left: image_left,
		text_left: getWysiwygFromField(field_columns2_text_left),
		link_left: fields && fields.field_columns2_link_left,
		image_right: image_right,
		text_right: getWysiwygFromField(field_columns2_text_right),
		link_right: fields && fields.field_columns2_link_right
	}
}

export const fragment = graphql`
	fragment Columns2 on paragraph__columns2 {
		id
		field_columns2_display_square
		field_columns2_text_left {
			processed
		}
		field_columns2_text_right {
			processed
		}
		fields {
			field_columns2_link_left {
				title
				url
			}
			field_columns2_link_right {
				title
				url
			}
		}
		relationships {
			field_columns2_image_left {
				...Columns2Image
			}
			field_columns2_image_right {
				...Columns2Image
			}
		}
	}
`

export const imageFragment = graphql`
	fragment Columns2Image on media__image {
		...ImageMeta
		relationships {
			field_media_image {
				localFile {
					image: childImageSharp {
						fixed(width: 640, height: 280, cropFocus: CENTER) {
							...GatsbyImageSharpFixed
						}
					}
					imageSquare: childImageSharp {
						fixed(width: 640, height: 640, cropFocus: CENTER) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`
