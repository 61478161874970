import {Columns3Type} from 'blocks/columns3/columns3'
import {graphql} from 'gatsby'
import React from 'react'
import {Columns3Fragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getColumns3Data: (
	frag: Columns3Fragment
) => Columns3Type = frag => {
	const {
		field_columns3_display_square,
		field_columns3_text_left,
		field_columns3_text_center,
		field_columns3_text_right,
		fields,
		relationships
	} = frag

	const displaySquare = field_columns3_display_square
	const imageKey = displaySquare ? 'imageSquare' : 'image'

	let image_left = getImageFromField(relationships.field_columns3_image_left, imageKey)
	let image_center = getImageFromField(relationships.field_columns3_image_center, imageKey)
	let image_right = getImageFromField(relationships.field_columns3_image_right, imageKey)

	return {
		display_square: field_columns3_display_square,
		image_left: image_left,
		text_left: getWysiwygFromField(field_columns3_text_left),
		link_left: fields && fields.field_columns3_link_left,
		image_center: image_center,
		text_center: getWysiwygFromField(field_columns3_text_center),
		link_center: fields && fields.field_columns3_link_center,
		image_right: image_right,
		text_right: getWysiwygFromField(field_columns3_text_right),
		link_right: fields && fields.field_columns3_link_right
	}
}

export const fragment = graphql`
	fragment Columns3 on paragraph__columns3 {
		id
		field_columns3_display_square
		field_columns3_text_center {
			processed
		}
		field_columns3_text_left {
			processed
		}
		field_columns3_text_right {
			processed
		}
		fields {
			field_columns3_link_center {
				title
				url
			}
			field_columns3_link_left {
				title
				url
			}
			field_columns3_link_right {
				title
				url
			}
		}
		relationships {
			field_columns3_image_left {
				...ImageColumns3
			}
			field_columns3_image_center {
				...ImageColumns3
			}
			field_columns3_image_right {
				...ImageColumns3
			}
		}
	}
`

export const fragmentImage = graphql`
	fragment ImageColumns3 on media__image {
		...ImageMeta
		relationships {
			field_media_image {
				localFile {
					image: childImageSharp {
						fixed(width: 560, height: 240, cropFocus: CENTER) {
							...GatsbyImageSharpFixed
						}
					}
					imageSquare: childImageSharp {
						fixed(width: 560, height: 560, cropFocus: CENTER) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`
