import {SpecificationsType} from 'blocks/specifications/specifications'
import {graphql} from 'gatsby'
import {SpecificationsFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'

export const getSpecificationsData: (
	frag: SpecificationsFragment
) => SpecificationsType = (frag) => {
	return {
		items: (frag.relationships?.field_specification_items || []).map(
			(item) => ({
				label:
					item.__typename === 'paragraph__specification_longitem'
						? item.field_specification_longitem_lab
						: item.field_specification_item_label,
				value: getWysiwygFromField(
					item.__typename === 'paragraph__specification_longitem'
						? item.field_specification_longitem_val
						: item.field_specification_item_value
				)
			})
		)
	}
}

export const fragment = graphql`
	fragment Specifications on paragraph__specifications {
		id
		relationships {
			field_specification_items {
				__typename
				... on paragraph__specification_item {
					field_specification_item_label
					field_specification_item_value {
						processed
					}
				}
				... on paragraph__specification_longitem {
					field_specification_longitem_lab
					field_specification_longitem_val {
						processed
					}
				}
			}
		}
	}
`
