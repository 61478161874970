import {graphql} from 'gatsby'
import {IframeblockType} from './iframeblock'
import {IframeblockFragment} from 'types/graphtypes'

export const getIframeblockData: (
	frag: IframeblockFragment
) => IframeblockType = frag => {
	const {
		field_iframeblock_title,
		field_iframeblock_fullwidth,
		field_iframeblock_aspectratio,
		field_iframeblock_embed_code,
		field_iframeblock_minwidth
	} = frag

	return {
		title: field_iframeblock_title,
		fullwidth: field_iframeblock_fullwidth,
		aspectratio: field_iframeblock_aspectratio,
		embed_code: field_iframeblock_embed_code,
		minwidth: field_iframeblock_minwidth
	}
}

export const fragment = graphql`
	fragment IframeBlock on paragraph__iframeblock {
		id
		field_iframeblock_aspectratio
		field_iframeblock_embed_code
		field_iframeblock_fullwidth
		field_iframeblock_title
		field_iframeblock_minwidth
	}
`
