import {graphql} from 'gatsby'
import React from 'react'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'
import {TestimonialsType} from 'blocks/testimonials/testimonials'
import {TestimonialsFragment} from 'types/graphtypes'

export const getTestimonialsData: (
	frag: TestimonialsFragment
) => TestimonialsType = frag => {
	const {
		relationships: {field_testimonials_items}
	} = frag

	const items = field_testimonials_items.map(item => {
		return {
			name: item.field_testimonials_name,
			quote: getWysiwygFromField(item.field_testimonials_quote),
			link: item.fields &&
				item.fields.field_testimonials_link && {
					url: item.fields.field_testimonials_link.url,
					title: item.fields.field_testimonials_link.title
				},
			avatar_image: getImageFromField(
				item.relationships.field_testimonials_avatar_image
			),
			bg_image: getImageFromField(
				item.relationships.field_testimonials_bg_image
			)
		}
	})

	return {
		items
	}
}

export const fragment = graphql`
	fragment Testimonials on paragraph__testimonials {
		id
		relationships {
			field_testimonials_items {
				fields {
					field_testimonials_link {
						title
						url
					}
				}
				field_testimonials_name
				field_testimonials_quote {
					processed
				}
				relationships {
					field_testimonials_bg_image {
						...ImageMeta
						relationships {
							field_media_image {
								localFile {
									image: childImageSharp {
										fluid(maxWidth: 1200) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
					field_testimonials_avatar_image {
						...ImageMeta
						relationships {
							field_media_image {
								localFile {
									image: childImageSharp {
										fixed(width: 200, height: 200) {
											...GatsbyImageSharpFixed
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
