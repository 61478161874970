import {ServicesItemType, ServicesType} from 'blocks/services/services'
import {graphql} from 'gatsby'
import React from 'react'
import {ServicesFragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getServicesData: (frag: ServicesFragment) => ServicesType = (
	frag
) => {
	const {
		field_services_text,
		field_services_display_right,
		relationships: {field_services_background_image, field_services_items}
	} = frag

	let items: Array<ServicesItemType> = []
	field_services_items.forEach((item) => {
		if (item.__typename === 'paragraph__services_item') {
			const image = getImageFromField(
				item.relationships.field_services_item_image
			)
			const text = getWysiwygFromField(item.field_services_item_text)
			const link = item.fields && item.fields.field_services_item_link

			items.push({
				image,
				link,
				text
			})
		} else if (item.__typename === 'paragraph__services_link') {
			let hero
			let image = null
			let text = ''
			const link =
				item.relationships.field_services_link_link &&
				item.relationships.field_services_link_link.fields &&
				item.relationships.field_services_link_link.fields.slug

			item.relationships.field_services_link_link &&
				item.relationships.field_services_link_link.relationships &&
				item.relationships.field_services_link_link.relationships.field_blocks.forEach(
					(block) => {
						if (block.__typename === 'paragraph__hero') {
							hero = block
						}
					}
				)

			if (hero) {
				image = getImageFromField(
					hero.relationships.field_hero_background_image
				)
				if (hero.field_hero_title) {
					text = text + '<h5>' + hero.field_hero_title + '</h5>'
				}
				if (getWysiwygFromField(hero.field_hero_description)) {
					text = text + getWysiwygFromField(hero.field_hero_description)
				}
			}

			items.push({image, link: {url: link}, text})
		}
	})

	return {
		background_image: getImageFromField(field_services_background_image),
		text: getWysiwygFromField(field_services_text),
		display_right: field_services_display_right,
		items: items
	}
}

export const fragment = graphql`
	fragment Services on paragraph__services {
		id
		field_services_display_right
		field_services_text {
			format
			processed
		}
		relationships {
			field_services_background_image {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							image: childImageSharp {
								fluid(maxWidth: 1200, srcSetBreakpoints: [300, 600]) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			field_services_items {
				... on paragraph__services_item {
					field_services_item_text {
						processed
					}
					fields {
						field_services_item_link {
							url
						}
					}
					relationships {
						field_services_item_image {
							...ImageMeta
							relationships {
								field_media_image {
									localFile {
										absolutePath
										image: childImageSharp {
											fixed(width: 420, height: 200, cropFocus: CENTER) {
												...GatsbyImageSharpFixed
											}
										}
									}
								}
							}
						}
					}
				}
				... on paragraph__services_link {
					relationships {
						field_services_link_link {
							fields {
								slug
							}
							relationships {
								field_blocks {
									... on paragraph__hero {
										field_hero_title
										field_hero_description {
											processed
										}
										relationships {
											field_hero_background_image {
												...ImageMeta
												relationships {
													field_media_image {
														localFile {
															absolutePath
															image: childImageSharp {
																fixed(
																	width: 420
																	height: 200
																	cropFocus: CENTER
																) {
																	...GatsbyImageSharpFixed
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
