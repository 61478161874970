import React from 'react'
import {getWysiwygFromField} from '../../util/drupal'
import {graphql} from 'gatsby'
import {TextblockType} from 'blocks/textblock/textblock'
import {TextblockFragment} from 'types/graphtypes'

export const getTextblockData: (
	frag: TextblockFragment
) => TextblockType = frag => {
	const {field_textblock_text, field_textblock_display_dark} = frag

	return {
		text: getWysiwygFromField(field_textblock_text),
		display_dark: field_textblock_display_dark
	}
}

export const fragment = graphql`
	fragment Textblock on paragraph__textblock {
		id
		field_textblock_display_dark
		field_textblock_text {
			processed
		}
	}
`
