import {graphql} from 'gatsby'
import React from 'react'
import {ListFragment} from 'types/graphtypes'
import {ListType} from 'blocks/list/list'
import {getWysiwygFromField} from 'util/drupal'

export const getListData: (frag: ListFragment) => ListType = frag => {
	const {
		field_list_title,
		field_list_items
	} = frag

	return {
		title: field_list_title,
		items: field_list_items.map(item => getWysiwygFromField(item))
	}
}

export const fragment = graphql`
	fragment List on paragraph__list {
		id
		field_list_title
		field_list_items {
			processed
		}
	}
`
