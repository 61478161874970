import {ProductsPreviewType} from 'blocks/productspreview/productspreview'
import {graphql} from 'gatsby'
import React from 'react'
import {ProductsPreviewFragment} from 'types/graphtypes'
import {getImageFromField} from 'util/drupal'

export const getProductsPreviewData: (
	frag: ProductsPreviewFragment
) => ProductsPreviewType = (frag) => {
	return {
		cta: frag.fields?.field_productspreview_cta,
		items: (frag.relationships?.field_productspreview_items || []).map(
			(item) => ({
				url: item.fields.slug,
				image: getImageFromField(item.relationships.field_product_image)
			})
		)
	}
}

export const fragment = graphql`
	fragment ProductsPreview on paragraph__productspreview {
		id
		fields {
			field_productspreview_cta {
				title
				url
			}
		}
		relationships {
			field_productspreview_items {
				fields {
					slug
				}
				relationships {
					field_product_image {
						...ImageMeta
						relationships {
							field_media_image {
								localFile {
									image: childImageSharp {
										fixed(width: 650, height: 650, cropFocus: CENTER) {
											...GatsbyImageSharpFixed
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
