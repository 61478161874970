import {graphql} from 'gatsby'

export const getJobsData = (frag) => {
	return null
}

export const fragment = graphql`
	fragment Jobs on paragraph__jobs {
		id
	}
`
