import { graphql } from 'gatsby'
import { GalleryFragment } from 'types/graphtypes'
import { GalleryItemType, GalleryType } from 'blocks/gallery/gallery'
import { getImageFromField, getWysiwygFromField } from 'util/drupal'

export const getGalleryData: (frag: GalleryFragment) => GalleryType = frag => {
	const {
		relationships: { field_gallery_items }
	} = frag

	const items: Array<GalleryItemType> = field_gallery_items.map(item => {
		switch (item.type) {
			case 'paragraph__gallery_image':
				const image = getImageFromField(item.relationships.field_gallery_image_image)
				return {
					type: 'image',
					image: getImageFromField(item.relationships.field_gallery_image_image),
					thumbnail: getImageFromField(item.relationships.field_gallery_image_image, 'thumbnail'),
					description: getWysiwygFromField(item.field_gallery_image_description)
				}
			case 'paragraph__gallery_video':
				return {
					type: 'video',
					embed_code: item.field_gallery_video_embed_code,
					description: getWysiwygFromField(
						item.field_gallery_video_description
					),
					thumbnail: getImageFromField(
						item.relationships.field_gallery_video_thumbnail
					)
				}
			default:
				throw 'Unknown gallery item type'
		}
	})

	return {
		items
	}
}

export const fragment = graphql`
	fragment Gallery on paragraph__gallery {
		id
		relationships {
			field_gallery_items {
				type: __typename
				... on paragraph__gallery_image {
					id
					field_gallery_image_description {
						processed
					}
					relationships {
						field_gallery_image_image {
							...ImageMeta
							relationships {
								field_media_image {
									localFile {
										thumbnail: childImageSharp {
											fixed(width: 250, height: 180) {
												...GatsbyImageSharpFixed
											}
										}
										image: childImageSharp {
											fluid(maxWidth: 900) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
						}
					}
				}
				... on paragraph__gallery_video {
					id
					field_gallery_video_description {
						processed
					}
					field_gallery_video_embed_code
					relationships {
						field_gallery_video_thumbnail {
							...ImageMeta
							relationships {
								field_media_image {
									localFile {
										image: childImageSharp {
											fixed(width: 250, height: 180) {
												...GatsbyImageSharpFixed
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
