import {FaqType} from 'blocks/faq/faq'
import {graphql} from 'gatsby'
import {FaqFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'

export const getFaqData: (frag: FaqFragment) => FaqType = (frag) => {
	return {
		title: frag.field_faq_title,
		items: (frag.relationships?.field_faq_items || []).map((item) => ({
			label: getWysiwygFromField(item.field_faq_item_label),
			description: getWysiwygFromField(item.field_faq_item_description)
		}))
	}
}

export const fragment = graphql`
	fragment Faq on paragraph__faq {
		id
		field_faq_title
		relationships {
			field_faq_items {
				field_faq_item_description {
					processed
				}
				field_faq_item_label {
					processed
				}
			}
		}
	}
`
