import {graphql} from 'gatsby'
import {SearchFragment} from 'types/graphtypes'
import {SearchType} from './search'

export const getSearchData: (frag: SearchFragment) => SearchType = frag => {
	const {
		field_search_button,
		field_search_more,
		field_search_placeholder
	} = frag

	return {
		button: field_search_button,
		more: field_search_more,
		placeholder: field_search_placeholder
	}
}

export const fragment = graphql`
	fragment Search on paragraph__search {
		field_search_button
		field_search_more
		field_search_placeholder
	}
`
