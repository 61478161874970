import {graphql} from 'gatsby'
import {JobPreviewFragment, RelatedjobsFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'
import {RelatedjobItemType, RelatedjobsType} from './relatedjobs'

export const getRelatedjobsData: (
	frag: RelatedjobsFragment
) => RelatedjobsType = (frag) => {
	const {field_relatedjobs_text_left, field_relatedjobs_title_right} = frag

	return {
		text_left: getWysiwygFromField(field_relatedjobs_text_left),
		title_right: field_relatedjobs_title_right,
	}
}

export const jobFragmentToJobItem: (
	frag: JobPreviewFragment
) => RelatedjobItemType = frag => {
	return {
		title: frag.title,
		short_description: getWysiwygFromField(frag.field_short_description),
		slug: frag.fields && frag.fields.slug
	}
}

// export const useStaticJobsQuery = () => {
// 	const data = useStaticQuery(graphql`
// 		query RelatedjobsStatic {
// 			allNodeJob(
// 				limit: 4
// 				sort: {fields: field_date, order: DESC}
// 				filter: {field_job_status: {eq: true}}
// 			) {
// 				jobs: nodes {
// 					...JobPreview
// 				}
// 			}
// 		}
// 	`)

// 	return data.allNodeJob.jobs.map(item => jobFragmentToJobItem(item))
// }

export const fragment = graphql`
	fragment Relatedjobs on paragraph__relatedjobs {
		field_relatedjobs_title_right
		field_relatedjobs_text_left {
			processed
		}
	}

	fragment JobPreview on node__job {
		title
		field_short_description {
			processed
		}
		fields {
			slug
		}
	}
`
