import {CalendarType} from 'blocks/calendar/calendar'
import {graphql} from 'gatsby'
import React from 'react'
import {CalendarFragment} from 'types/graphtypes'
import {getImageFromField} from 'util/drupal'

export const getCalendarData: (
	frag: CalendarFragment
) => CalendarType = frag => {
	const {
		field_calendar_title,
		relationships: {
			field_calendar_highlighted,
			field_calendar_highlighted_image
		}
	} = frag


	return {
		title: field_calendar_title,
		highlighted: field_calendar_highlighted && {
			...field_calendar_highlighted,
			url: field_calendar_highlighted.fields.slug
		},
		highlighted_image: getImageFromField(field_calendar_highlighted_image)
	}
}

export const fragment = graphql`
	fragment Calendar on paragraph__calendar {
		field_calendar_title
		relationships {
			field_calendar_highlighted {
				fields {
					slug
				}
				date: field_date(formatString: "D MMM YYYY", locale: "nl")
				endDate: field_end_date(formatString: "D MMM YYYY", locale: "nl")
				title
			}
			field_calendar_highlighted_image {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							image: childImageSharp {
								fluid(maxWidth: 960) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
