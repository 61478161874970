import {graphql} from 'gatsby'
import {ReadingroomFragment} from 'types/graphtypes'
import {ReadingroomType} from './readingroom'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

export const getReadingroomData: (
	frag: ReadingroomFragment
) => ReadingroomType = frag => {
	const {field_reading_title, relationships} = frag

	return {
		title: field_reading_title,
		highlighted: (relationships.field_reading_featured_articles || []).map(item =>
			previewFragmentToPreviewItem(item.fields.preview)
		),
		categories: relationships.field_reading_categories.map(tag => tag.name)
	}
}

export const fragment = graphql`
	fragment Readingroom on paragraph__reading_room {
		field_reading_title
		relationships {
			field_reading_categories {
				name
			}
			field_reading_featured_articles {
				... on node__press {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
				... on node__article {
					id
					fields {
						preview {
							...Preview
						}
					}
				}
			}
		}
	}
`
