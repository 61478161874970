import { ContactType } from 'blocks/contact/contact'
import { ContactFragment } from 'types/graphtypes'
import { getImageFromField, getWysiwygFromField } from 'util/drupal'
import { graphql } from 'gatsby'

export const getContactData: (frag: ContactFragment) => ContactType = frag => {
	const {
		field_contact_text,
		drupal_internal__id,
		fields,
		relationships: { field_contact_avatar_image }
	} = frag

	return {
		text: getWysiwygFromField(field_contact_text),
		paragraphID: drupal_internal__id,
		avatar_image: getImageFromField(field_contact_avatar_image),
		link: fields ? fields.field_contact_link : null
	}
}

export const fragment = graphql`
	fragment Contact on paragraph__contact {
		field_contact_text {
			processed
		}
		drupal_internal__id
		fields {
			field_contact_link {
				title
				url
			}
		}
		relationships {
			field_contact_avatar_image {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							image: childImageSharp {
								fixed(width: 250) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	}
`
