import React from 'react'
import {graphql} from 'gatsby'
import {TextblockFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'
import {ErrorType} from 'blocks/error/error'

export const getErrorData: (
  frag: TextblockFragment
) => ErrorType = frag => {
  const {field_error_description} = frag

  return {
    description: getWysiwygFromField(field_error_description),
  }
}

export const fragment = graphql`
	fragment Error on paragraph__error {
		id
		field_error_description {
			processed
		}
	}
`
