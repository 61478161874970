import {DownloadsItemType, DownloadsType} from 'blocks/downloads/downloads'
import {graphql} from 'gatsby'
import {DownloadsFragment} from 'types/graphtypes'

export const getDownloadsData: (frag: DownloadsFragment) => DownloadsType = (
	frag
) => {
	const {field_downloads_title, relationships, fields} = frag

	let items: Array<DownloadsItemType> = []

	relationships?.field_downloads_items?.forEach((item) => {
		if (item.__typename === 'paragraph__downloads_file') {
			const url =
				item.relationships?.field_downloads_file_file?.relationships
					?.field_media_file?.localFile?.publicURL
			const title =
				item.field_downloads_file_title ||
				item.relationships?.field_downloads_file_file?.name
			items.push({type: 'file', url, title})
		} else if (item.__typename === 'paragraph__downloads_link') {
			const url = item.fields?.field_downloads_link?.url
			const title = item.fields?.field_downloads_link?.title
			items.push({type: 'link', url, title})
		}
	})

	relationships?.field_downloads_files?.forEach((file) => {
		const url = file.relationships?.field_media_file?.localFile?.publicURL
		items.push({type: 'file', url, title: file.name})
	})

	fields?.field_downloads_links?.forEach((link) => {
		items.push({type: 'link', url: link.url, title: link.title})
	})

	return {
		title: field_downloads_title,
		items: items
	}
}

export const fragment = graphql`
	fragment Downloads on paragraph__downloads {
		field_downloads_title
		fields {
			field_downloads_links {
				title
				url
			}
		}
		relationships {
			field_downloads_files {
				name
				relationships {
					field_media_file {
						localFile {
							publicURL
							name
						}
					}
				}
			}
			field_downloads_items {
				... on paragraph__downloads_file {
					field_downloads_file_title
					relationships {
						field_downloads_file_file {
							name
							relationships {
								field_media_file {
									localFile {
										publicURL
										name
									}
								}
							}
						}
					}
				}
				... on paragraph__downloads_link {
					fields {
						field_downloads_link {
							title
							url
						}
					}
				}
			}
		}
	}
`
