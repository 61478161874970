import {ProcessType} from 'blocks/process/process'
import {graphql} from 'gatsby'
import React from 'react'
import {ProcessFragment} from 'types/graphtypes'
import {getWysiwygFromField} from 'util/drupal'

export const getProcessData: (frag: ProcessFragment) => ProcessType = frag => {
	const {
		field_process_text,
		fields: {field_process_links}
	} = frag

	return {
		text: getWysiwygFromField(field_process_text),
		links: field_process_links.map(link => ({
			id: link.url && link.url.replace('internal:', ''),
			url: link.url,
			title: link.title
		}))
	}
}

export const fragment = graphql`
	fragment Process on paragraph__process {
		id
		fields {
			field_process_links {
				url
				title
			}
		}
		field_process_text {
			processed
		}
	}
`
