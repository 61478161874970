import { CenteredType } from 'blocks/centered/centered'
import { graphql } from 'gatsby'
import { CenteredFragment } from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getCenteredData: (
	frag: CenteredFragment
) => CenteredType = frag => {
	const {
		field_centered_text,
		relationships: { field_centered_background }
	} = frag

	return {
		text: getWysiwygFromField(field_centered_text),
		overlay:
			field_centered_background &&
			field_centered_background.field_overlay_percentage,
		background: getImageFromField(field_centered_background),
		darkText: field_centered_background &&
			field_centered_background.field_use_dark_colors
	}
}

export const fragment = graphql`
	fragment Centered on paragraph__centered {
		field_centered_text {
			processed
		}
		relationships {
			field_centered_background {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							absolutePath
							image: childImageSharp {
								fluid(maxWidth: 1200, srcSetBreakpoints: [300, 600]) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
