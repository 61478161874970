import { graphql } from 'gatsby'
import React from 'react'
import { ProductsFragment } from 'types/graphtypes'
import { ProductsType } from 'blocks/products/products'

export const getProductsData: (frag: ProductsFragment) => ProductsType = frag => {
  return {
    producttype: labelToType(frag.relationships?.field_products_type?.label)
  }
}

const labelToType = (label: string) => {
  switch (label) {
    case '[Product] Camera':
      return 'camera' as const
  }
}

export const fragment = graphql`
	fragment Products on paragraph__products {
		id
    relationships {
      field_products_type {
        label
      }
    }
	}
`
