import {ImageblockType} from 'blocks/imageblock/imageblock'
import {graphql} from 'gatsby'
import React from 'react'
import {ImageBlockFragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getImageblockData: (
	frag: ImageBlockFragment
) => ImageblockType = frag => {
	const {
		field_imageblock_title,
		field_imageblock_caption,
		fields,
		relationships: {field_imageblock_image, field_imageblock_size}
	} = frag

	return {
		size: field_imageblock_size && field_imageblock_size.name,
		title: field_imageblock_title,
		image: getImageFromField(field_imageblock_image),
		caption: getWysiwygFromField(field_imageblock_caption),
		link: fields?.field_imageblock_link
	}
}

export const fragment = graphql`
	fragment ImageBlock on paragraph__imageblock {
		id
		field_imageblock_title
		field_imageblock_caption {
			processed
		}
		fields {
			field_imageblock_link {
				url
			}
		}
		relationships {
			field_imageblock_size {
				name
			}
			field_imageblock_image {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							image: childImageSharp {
								fixed(width: 1200) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	}
`
