import {ProductHeroType} from 'blocks/producthero/producthero'
import {graphql} from 'gatsby'
import {ProductsHeroFragment} from 'types/graphtypes'
import {getImageFromField} from 'util/drupal'

export const getProductHeroData: (
	frag: ProductsHeroFragment
) => ProductHeroType = (frag) => {
	return {
		background: getImageFromField(
			frag.relationships?.field_producthero_background
		),
		filters: (frag.relationships?.field_producthero_filters || []).map(
			(item) => ({
				label: item?.field_producthero_filter_label,
				value: item?.field_producthero_filter_value
			})
		)
	}
}

export const fragment = graphql`
	fragment ProductHero on paragraph__producthero {
		relationships {
			field_producthero_background {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							image: childImageSharp {
								fixed(width: 1400) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
			field_producthero_filters {
				field_producthero_filter_label
				field_producthero_filter_value
			}
		}
	}
`
