import React from 'react'
import { graphql } from 'gatsby'
import { SponsorsFragment } from 'types/graphtypes'
import { getImageFromField, getWysiwygFromField } from 'util/drupal'
import { SponsorsType } from './sponsors'

export const getSponsorsData: (
	frag: SponsorsFragment
) => SponsorsType = frag => {
	const {
		field_sponsors_title,
		relationships: { field_sponsors_logos }
	} = frag

	const sponsors = field_sponsors_logos.map(logo => ({
		title: logo.field_logos_title,
		items:
			logo.relationships &&
			logo.relationships.field_logos_items.map(item => ({
				link: item.fields && item.fields.field_logos_item_link.url,
				image: getImageFromField(item.relationships.field_logos_item_image)
			}))
	}))

	return {
		title: field_sponsors_title,
		logos: sponsors
	}
}

export const fragment = graphql`
	fragment Sponsors on paragraph__sponsors {
		field_sponsors_title
		relationships {
			field_sponsors_logos {
				field_logos_title
				relationships {
					field_logos_items {
						fields {
							field_logos_item_link {
								url
							}
						}
						relationships {
							field_logos_item_image {
								...ImageMeta
								relationships {
									field_media_image {
										localFile {
											image: childImageSharp {
												fluid(maxHeight: 170, quality: 100) {
													# presentationWidth
													...GatsbyImageSharpFluid
												}
												resolutions {
													src
													width
													height
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
