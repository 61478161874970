import React from 'react'
import {getWysiwygFromField} from 'util/drupal'
import {graphql} from 'gatsby'
import {HerohomeFragment} from 'types/graphtypes'
import {HeroHomeType} from 'blocks/herohome/herohome'

export const getHerohomeData: (
	frag: HerohomeFragment
) => HeroHomeType = frag => {
	const {field_herohome_description, fields} = frag

	const description = getWysiwygFromField(field_herohome_description)
		.replace('<p>', '<h1>')
		.replace('</p>', '</h1>')

	return {
		description: description,
		link: fields?.field_herohome_link
	}
}

export const fragment = graphql`
	fragment Herohome on paragraph__herohome {
		id
		field_herohome_description {
			processed
		}
		fields {
			field_herohome_link {
				title
				url
			}
		}
	}
`
