import {BackgroundBlockType} from 'blocks/backgroundblock/backgroundblock'
import {graphql} from 'gatsby'
import React from 'react'
import {BackgroundBlockFragment} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'

export const getBackgroundblockData: (
	frag: BackgroundBlockFragment
) => BackgroundBlockType = frag => {
	const {
		field_backgroundblock_dark,
		field_backgroundblock_text_left,
		field_backgroundblock_text_right,
		relationships: { field_backgroundblock_background }
	} = frag

	return {
		background: getImageFromField(field_backgroundblock_background),
		overlay:
			field_backgroundblock_background &&
			field_backgroundblock_background.field_overlay_percentage,
		text_left: getWysiwygFromField(field_backgroundblock_text_left),
		text_right: getWysiwygFromField(field_backgroundblock_text_right),
		dark: field_backgroundblock_dark
	}
}

export const fragment = graphql`
	fragment BackgroundBlock on paragraph__backgroundblock {
		id
		field_backgroundblock_text_left {
			processed
		}
		field_backgroundblock_text_right {
			processed
		}
		relationships {
			field_backgroundblock_background {
				...ImageMeta
				relationships {
					field_media_image {
						localFile {
							image: childImageSharp {
								fluid(maxWidth: 1200, srcSetBreakpoints: [300, 600]) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
